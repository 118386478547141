'use client'

import { BsFillChatLeftDotsFill } from 'react-icons/bs'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogTrigger } from '@/components/ui/dialog'
import { Textarea } from '../ui/textarea'
import { usePostFeedbackMutation } from '@/lib/redux'
import { usePathname } from 'next/navigation'
import { ReactNode, useState } from 'react'
import { useToast } from '../ui/use-toast'
import { SOMETHING_WRONG } from '@/config/messages'
import { SendEmail } from '../sendemailLInk'
import { tinyApiDocs } from '@/config/site'
import { DialogTitle } from '@radix-ui/react-dialog'

interface FeedbackModalProps {
  link?: ReactNode
}

export function FeedbackModal(props: FeedbackModalProps) {
  const [postFeedback, { isLoading }] = usePostFeedbackMutation()
  const [feedback, setFeedback] = useState<string>()
  const { toast } = useToast()
  const pathName = usePathname()
  const onSend = async () => {
    try {
      await postFeedback({
        page: pathName as string,
        feedback,
      })
      toast({
        title: 'Feedback Sent',
      })
      setFeedback('')
    } catch (e) {
      toast({
        title: SOMETHING_WRONG,
        variant: 'destructive',
      })
      console.log(e)
    }
  }
  return (
    <Dialog
      onOpenChange={() => {
        setFeedback('')
      }}
    >
      <DialogTrigger asChild>
        {props.link ? (
          props?.link
        ) : (
          <Button variant='outline' className='hidden gap-2 border-[#666666] text-white/80 md:flex'>
            <BsFillChatLeftDotsFill />
            Feedback
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className='px-10 focus:outline-none sm:max-w-lg'>
        <DialogTitle>Send Feedback</DialogTitle>
        <Textarea
          onChange={(e) => {
            setFeedback(e.target.value)
          }}
          rows={8}
          placeholder='Ideas to improve this page..'
          className='mt-4 text-lg focus:outline-none'
        />
        <DialogFooter className='flex w-full items-center justify-between'>
          <p className='w-full text-left text-muted-foreground'>
            Need help?{' '}
            <SendEmail>
              <span className='mx-1 text-white underline'>Contact us</span>
            </SendEmail>
            or{' '}
            <a target='_blank' href={tinyApiDocs} className='text-white underline' rel='noreferrer'>
              see docs.
            </a>
          </p>
          <Button disabled={isLoading} onClick={onSend} type='submit' variant={'secondary'}>
            Send
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
