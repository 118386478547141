import { cn } from '@/lib/utils'
import * as React from 'react'

export type InputProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'ref'
> & {
  ref?: React.Ref<HTMLInputElement>
  label?: string
  error?: string
  className?: string
  useUppercaseLabel?: boolean
  noRings?: boolean
  labelClasses?: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ label, error, type = 'text', className, useUppercaseLabel = false, noRings, ...props }, ref) => {
    return (
      <div className={cn('text-xs sm:text-sm', className)}>
        <label>
          {label && (
            <span
              className={cn(
                'block font-medium text-foreground',
                useUppercaseLabel ? 'mb-1 uppercase sm:mb-2' : 'mb-1',
                props.labelClasses
              )}
            >
              {label}
              {props.required && <sup className='ml-1 inline-block text-[13px] text-primary'>*</sup>}
            </span>
          )}
          <input
            type={type}
            className={cn(
              'flex w-full rounded-md border border-input  bg-transparent p-3 text-sm text-foreground ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground checked:bg-secondary focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-border/30 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
              noRings &&
                'outline-none ring-offset-transparent focus:outline-none focus:ring-0 focus-visible:ring-0 focus-visible:ring-transparent',
              className
            )}
            ref={ref}
            {...props}
          />
        </label>
        {error && (
          <span role='alert' className='mt-2 block text-primary sm:mt-2.5'>
            {error}
          </span>
        )}
      </div>
    )
  }
)
Input.displayName = 'Input'

export { Input }
