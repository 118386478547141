import { ReactNode } from 'react'

interface SendEmailProps {
  children: ReactNode
}

export function SendEmail({ children }: SendEmailProps) {
  return (
    <a target='_blank' href='mailto:hello@tinyapi.co' rel='noreferrer'>
      {children}
    </a>
  )
}
