import * as React from 'react'
import { cn } from '@/lib/utils'
import { Popover, PopoverTrigger } from '@/components/ui/'
import { useProject } from '@/hooks/useProject'
import { Skeleton } from './ui/skeleton'
import { LogoIcon } from '@/components/Logo'
import Image from 'next/image'

type PopoverTriggerProps = React.ComponentPropsWithoutRef<typeof PopoverTrigger>

interface TeamSwitcherProps extends PopoverTriggerProps {
  className?: string
}

export function TeamSwitcher({ className }: TeamSwitcherProps) {
  const [open, setOpen] = React.useState(false)
  const { activeProject, isLoading } = useProject()

  const projectLink = `https://tinyapi.co/${activeProject?.slug}`

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger>
        <div
          aria-expanded={open}
          aria-label='Select a team'
          className={cn(
            'relative flex items-center gap-2 rounded-md font-ibm text-base  font-semibold hover:bg-muted/10',
            className
          )}
        >
          {activeProject?.logo ? <Image src={activeProject.logo} width={30} height={30} alt={''} /> : <LogoIcon />}
          {isLoading ? (
            <Skeleton className='h-4 w-10'></Skeleton>
          ) : (
            <>
              <span className='hidden truncate md:block'>{activeProject?.name}</span>
              {/*<LuMoreHorizontal className='mx-3 hidden text-base md:block' />*/}
            </>
          )}
        </div>
      </PopoverTrigger>
      {/*<PopoverContent align='start' className='flex w-[260px] gap-2 px-6 py-4 scrollbar-hide'>*/}
      {/*  <div>*/}
      {/*    {activeProject?.logo ? (*/}
      {/*      <Avatar>*/}
      {/*        <AvatarImage src={activeProject?.logo}></AvatarImage>*/}
      {/*      </Avatar>*/}
      {/*    ) : (*/}
      {/*      <LogoIcon />*/}
      {/*    )}*/}
      {/*  </div>*/}
      {/*  <div>*/}
      {/*    <h2 className='text-sm font-medium'>{activeProject?.name}</h2>*/}
      {/*    <Link href={projectLink} target={'_blank'} className='text-xs text-muted-foreground'>*/}
      {/*      {projectLink}*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*</PopoverContent>*/}
    </Popover>
  )
}
