'use client'

import { useGetApiStatusQuery } from '@/lib/redux'

function AllApiStatus() {
  const { data: serviceStatus, isLoading, isError } = useGetApiStatusQuery()

  if (isLoading) {
    return (
      <div className='flex h-12 w-full max-w-[500px] animate-pulse items-center bg-accent'>
        <p className='px-4'>Fetching Api Status</p>
      </div>
    )
  }

  if (isError) {
    return (
      <p className='flex h-11 items-center rounded border px-4 text-center text-destructive '>
        Something went wrong not able to fetch API status
      </p>
    )
  }

  return (
    <div className='flex items-center space-x-4 rounded-xl border px-3 py-3 font-sora text-sm sm:px-6 sm:py-3'>
      <p className='text-white'>Status:</p>
      <div>
        <svg className='h-2 w-2 sm:h-4 sm:w-4' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <circle cx='8' cy='8' r='8' fill={serviceStatus?.color} />
        </svg>
      </div>
      <p
        style={{
          color: serviceStatus?.color,
        }}
      >
        {serviceStatus?.message}
      </p>
    </div>
  )
}

export default AllApiStatus
