import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from '@/components/ui/'
import { IUser } from '@/types/user'
import Logout from '@/app/(auth)/logout'
import { AiOutlineUser } from 'react-icons/ai'
import Link from 'next/link'

interface UserNavProps {
  data: IUser | null
}

export function UserNav(props: UserNavProps) {
  if (!props.data) {
    return null
  }
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant='ghost' className='relative flex h-10'>
          <Avatar className='h-8 w-8'>
            <AvatarImage src={props?.data?.profilePicture || ''} alt='image' />
            <AvatarFallback>
              <AiOutlineUser />
            </AvatarFallback>
          </Avatar>
          {/*<p className='ml-6 hidden flex-col text-sm font-normal lg:flex'>*/}
          {/*  <span className='font-medium'>*/}
          {/*    {props?.data?.firstName} {props?.data?.lastName}*/}
          {/*  </span>*/}
          {/*  <span>{props.data?.email}</span>*/}
          {/*</p>*/}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className='w-56' align='end' forceMount>
        <DropdownMenuLabel className='font-normal'>
          <div className='flex flex-col space-y-1'>
            <p className='text-sm font-medium leading-none'>{props?.data?.firstName}</p>
            <p className='text-xs leading-none text-muted-foreground'>{props?.data?.email}</p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <Link href={`/profile/${props?.data?.username}`}>
            <DropdownMenuItem>
              Profile
              <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>
            </DropdownMenuItem>
          </Link>
          <DropdownMenuItem>
            <Link href={'/account'}>Account</Link>
            <DropdownMenuShortcut>⌘S</DropdownMenuShortcut>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <Logout />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
