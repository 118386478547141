'use client'

import { DropdownMenuItem, DropdownMenuShortcut } from '@/components/ui'
import { toast } from '@/components/ui/use-toast'
import { useLogoutMutation } from '@/lib/redux'

function Logout() {
  const [logout] = useLogoutMutation()
  const handleLogout = async () => {
    try {
      await logout().unwrap()
      window.location.reload()
    } catch (e) {
      toast({ title: 'Something Went Wrong', variant: 'destructive' })
    }
  }

  return (
    <DropdownMenuItem onClick={handleLogout}>
      Log out
      <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
    </DropdownMenuItem>
  )
}

export default Logout
