'use client'

import AuthenticatedFooter from './authenticatedFooter'
import { useGetUserQuery } from '@/lib/redux'
import BaseFooter from './baseFooter'

function Footer() {
  const { data, isError } = useGetUserQuery()

  if (isError) {
    return <BaseFooter />
  }

  return data?.results[0].email ? <AuthenticatedFooter /> : <BaseFooter />
}

export default Footer
