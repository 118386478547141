import clsx from 'clsx'
import React from 'react'
import { ButtonProps } from './ui'

interface Props extends ButtonProps {}

const Spinner = (Props: Props) => {
  const { variant: variant } = Props
  return (
    <div className='flex justify-center'>
      <div
        className={clsx(
          'mr-3 h-4 w-4 animate-spin rounded-full border-4 border-dotted',
          variant == 'secondary' ? 'border-black' : 'border-white'
        )}
      ></div>
    </div>
  )
}

export function NewSpinner() {
  return <div className='spinner'></div>
}

export default Spinner
