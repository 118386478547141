'use client'

import { useRouter } from 'next/navigation'
import { cn } from '@/lib/utils'
import { useState } from 'react'

import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet'
import { Button, ButtonProps } from '@/components/ui/button'
import { Logo } from '@/components/Logo'
import { LuMenu } from 'react-icons/lu'
import { siteRoutes } from '@/config/site'

export function MobileNav() {
  const [open, setOpen] = useState(false)

  const closeOpen = () => setOpen(false)

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button
          variant='ghost'
          className='mr-2 px-0 text-base hover:bg-transparent focus-visible:bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 md:hidden'
        >
          <LuMenu />
        </Button>
      </SheetTrigger>
      <SheetContent side='left' className='pr-0'>
        <MobileLink href='/' className='flex items-center' onOpenChange={closeOpen}>
          <Logo />
        </MobileLink>
        <div className='my-10 flex min-h-[70vh] flex-col items-start gap-y-2'>
          <MobileLink onOpenChange={closeOpen} href={siteRoutes.apis}>
            Market Place
          </MobileLink>
          <MobileLink onOpenChange={closeOpen} href={siteRoutes.apis}>
            Publish Your API
          </MobileLink>
          <div className='flex flex-col items-start gap-y-2'>
            <h2 className='mb-3 text-sm text-muted-foreground'>Resources</h2>
            <MobileLink onOpenChange={closeOpen} href={siteRoutes.roadmap}>
              Roadmap
            </MobileLink>
            <MobileLink onOpenChange={closeOpen} href={siteRoutes.hub}>
              How it Works
            </MobileLink>
            <MobileLink onOpenChange={closeOpen} href={'/resources/request-for-apis'}>
              Requests for APIs
            </MobileLink>
          </div>
          <div className='mt-auto flex flex-col items-start gap-y-2'>
            <MobileLink onOpenChange={closeOpen} href={siteRoutes.login}>
              Login
            </MobileLink>
            <MobileLink onOpenChange={closeOpen} href={siteRoutes.register}>
              Register
            </MobileLink>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  )
}

interface MobileLinkProps extends ButtonProps {
  onOpenChange?: () => void
  children: React.ReactNode
  className?: string
  href: string
}

function MobileLink({ href, onOpenChange, className, children, ...props }: MobileLinkProps) {
  const router = useRouter()
  return (
    <Button
      variant={'ghost'}
      onClick={() => {
        router.push(href)
        onOpenChange && onOpenChange()
      }}
      className={cn('', className)}
      {...props}
    >
      {children}
    </Button>
  )
}
