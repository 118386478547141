'use client'

import Link from 'next/link'
import { Logo } from './Logo'
import { UserNav } from './navbar/userNav'
import { authRoutes, siteRoutes, tallyForm } from '@/config/site'
import { selectUser } from '@/lib/redux'

import { NavItem, NavItemExternal } from './navbar/navItem'
import { AiOutlineAppstore, AiOutlineAppstoreAdd } from 'react-icons/ai'
import { Resources } from './resources'
import { TeamSwitcher } from './teamSwitcher'
import { cn } from '@/lib/utils'
import { FeedbackModal } from './modals/feedback'
import { LuLayoutDashboard } from 'react-icons/lu'
import { useSelector } from 'react-redux'
import { useParams, usePathname } from 'next/navigation'
import { MobileNav } from './navbar/mobileNav'

const navitems = [
  {
    label: 'Marketplace',
    href: siteRoutes.apis,
    Icon: AiOutlineAppstore,
  },
  {
    label: 'Published APIs',
    href: siteRoutes.publishedApis,
    Icon: AiOutlineAppstoreAdd,
  },
]

export const Header = () => {
  const user = useSelector(selectUser)
  const isLoggedIn = user?.email ? true : false
  const pathName = usePathname()
  const params = useParams()
  const isApiListingPage = 'api_slug' in params
  const notSticky = pathName.startsWith('/apis/') && isApiListingPage

  return (
    <header
      className={cn(
        'sticky top-0 z-[100] flex h-20 w-full items-center justify-between bg-background px-[5%] py-2 font-ibm',
        notSticky && 'relative'
      )}
    >
      <div className='flex w-full gap-6 '>
        {isLoggedIn ? <TeamSwitcher className='w-max' /> : <Logo />}
        <div
          className={cn(
            'hidden flex-shrink items-center lg:flex',
            isLoggedIn ? 'w-full justify-center' : 'justify-start'
          )}
        >
          <NavItem label={navitems[0].label} href={navitems[0].href} Icon={navitems[0].Icon} />
          {isLoggedIn && <NavItem label='Your Hub' href={siteRoutes.yourHub} Icon={LuLayoutDashboard} />}
          {isLoggedIn ? (
            <NavItem label={navitems[1].label} href={navitems[1].href} Icon={navitems[1].Icon} />
          ) : (
            <NavItemExternal href={tallyForm} label={'Publish Your API'} Icon={navitems[1].Icon} />
          )}
        </div>
      </div>
      <div className='flex min-w-max items-center gap-3'>
        <Resources />
        {isLoggedIn ? (
          <>
            <FeedbackModal />
            <UserNav data={user} />
          </>
        ) : (
          <>
            <MobileNav />
            <Link
              className='mr-4 hidden h-11 items-center rounded-md bg-accent px-6 font-semibold text-muted-foreground md:flex'
              href={authRoutes.login}
            >
              Log in
            </Link>
            <Link
              className='hidden h-11 items-center rounded-md border border-primary px-6 font-semibold text-primary md:flex '
              href={authRoutes.register}
            >
              Sign up
            </Link>
          </>
        )}
      </div>
    </header>
  )
}
