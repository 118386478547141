import Link from 'next/link'

import { FooterIcons } from './footericon'
import { Logo } from '..'
export const footerLinks = [
  {
    title: 'Product',
    links: [
      { title: 'Features', href: '#' },
      { title: `Pricing - It's Free`, href: '#' },
      { title: 'Currency Conversion API', href: '#' },
      { title: 'Weather Forecast API', href: '#' },
      { title: 'Integrations', href: '#' },
      { title: 'Customer Testimonials', href: '#' },
      { title: 'Case Studies    ', href: '#' },
    ],
  },
  {
    title: 'Resources',
    links: [
      { title: 'Business Guides', href: '#' },
      { title: 'Help Documentation', href: '#' },
      { title: 'FAQs', href: '#' },
      { title: `What's New?`, href: '#' },
      { title: `Release Notes           `, href: '#' },
    ],
  },
  {
    title: 'Quick Links',
    links: [
      { title: 'GDPR and Tiny API', href: '#' },
      { title: 'Industries Best Suited For', href: '#' },
      { title: 'Invoice Templates', href: '#' },
      { title: 'Become a TinyAPI affiliate', href: '#' },
    ],
  },
]

function BaseFooter() {
  return (
    <footer className='overflow-hidden px-[5%] py-10'>
      <div className='flex flex-col justify-between lg:flex-row'>
        <div>
          <div className='flex h-full flex-col gap-y-3'>
            <div className='text-grey flex items-center'>
              <Logo className='mr-4' />
            </div>
            <p className='max-w-sm font-normal opacity-90'>
              Discover our most popular and widely-used APIs, carefully selected to supercharge your applications.
            </p>
            <FooterIcons />
          </div>
        </div>
        <div className='flex flex-wrap'>
          {footerLinks.map((column) => (
            <div key={column.title} className='mt-10 min-w-[50%] lg:mt-0 lg:min-w-[18rem]'>
              <h3 className='mb-3 text-lg font-medium'>{column.title}</h3>
              <ul>
                {column.links.map((link) => (
                  <li key={link.title} className='[&_a]:last:mb-0'>
                    <Link className='hover:text-off-white mb-3 block opacity-70 transition-colors' href={link.href}>
                      {link.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </footer>
  )
}

export default BaseFooter
