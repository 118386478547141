import { SiteConfig } from '@/types'

export const tallyForm = 'https://tally.so/r/mRMvzv'
export const demoLink = 'https://fantastical.app/shashank/tiny-api-demo'
export const tinyApiDocs = 'https://docs.tinyapi.co'

export const siteConfig: SiteConfig = {
  name: 'Tiny API',
  description: '',
  url: '',
  ogImage: '',
  links: {
    twitter: 'https://twitter.com/TinyAPIHub',
    github: 'https://github.com/TinyAPIs/',
    linkedin: 'https://www.linkedin.com/company/tinyapi/',
    docs: 'https://docs.tinyapi.co',
    slack: 'https://tinyapi.slack.com/join/shared_invite/zt-20rzmvply-KkDU2itMk9_sdIT4jpS3Dg#/shared-invite/email',
  },
}

export const authRoutes = {
  login: '/login',
  register: '/signup',
  resetpassword: '/resetpassword',
  verified: '/verified',
  onBoarding: '/onboarding',
  emailSent: '/signup/sent',
  verify: '/signup/verify',
}

export const siteRoutes = {
  ...authRoutes,
  root: '/',
  createproject: '/createproject',
  getStarted: '/getstarted',
  privacypolicy: 'privacy-policy',
  apis: '/apis',
  hub: '/how-it-works',
  yourHub: '/your-hub',
  publishedApis: '/published-apis',
  roadmap: '/roadmap',
}

export const publicRoutes = {
  apis: '/apis',
  hub: '/how-it-works',
  root: '/',
  login: '/login',
  register: '/signup',
  resetpassword: '/resetpassword',
}
