import { tinyApiDocs } from '@/config/site'
import { Logo } from '..'
import AllApiStatus from '../allApiStatus'
import { FooterIcons } from './footericon'
import { FeedbackModal } from '../modals/feedback'

const footerTags = [
  {
    name: 'Documentation',
    link: tinyApiDocs,
  },
  {
    name: 'Contact Support',
    link: 'mailto:hello@tinyapi.co',
  },
  {
    name: "What's New ?",
    link: 'https://www.tinyapi.co/roadmap',
  },
  // {
  //   name: 'Report an issue',
  //   link: '#',
  // },
  // {
  //   name: 'Become an affiliate',
  //   link: '#',
  // },
]

const AuthenticatedFooter = () => {
  return (
    <div className='px-[5%] py-4 md:py-10'>
      <div className='flex flex-col gap-y-3 md:flex-row md:items-center md:justify-between'>
        <Logo />
        <AllApiStatus />
      </div>
      <div className='mt-10 flex w-full flex-col justify-between md:flex-row md:items-center'>
        <div className='flex w-full flex-col gap-6 md:flex-row md:items-center md:justify-between'>
          {footerTags.map(({ name, link }, i) => {
            return (
              <a target='_blank' href={link} key={i} className='text-white/60 hover:text-white' rel='noreferrer'>
                {name}
              </a>
            )
          })}
          <FeedbackModal link={<button className='text-white/60 hover:text-white'>Report an issue</button>} />
        </div>
        <FooterIcons />
      </div>
    </div>
  )
}

export default AuthenticatedFooter
