import { siteRoutes } from '@/config/site'
import { cn } from '@/lib/utils'
import Link from 'next/link'
import { type HTMLAttributes } from 'react'

interface LogoProps extends HTMLAttributes<HTMLDivElement> {}

function Logo(props: LogoProps) {
  return (
    <Link className='w-fit' href={siteRoutes.root}>
      <div
        className={cn(
          'flex h-12 w-32 flex-row items-center  justify-center gap-1 rounded-md bg-[#44444499] font-overpass font-bold ',
          props.className
        )}
      >
        <p className='w-max text-3xl text-primary'>/</p>
        <p className='w-max text-xl'>tinyapi</p>
      </div>
    </Link>
  )
}

interface LogoIconProps {
  width?: number
  className?: string
}
function LogoIcon({ width = 30, className }: LogoIconProps) {
  return (
    <div className={'overflow-hidden rounded-full'} style={{ width, height: width }}>
      <svg
        className={className}
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={width}
        viewBox={`0 0 ${width} ${width}`}
        fill='none'
      >
        <circle cx='15' cy='15' r='15' fill='#FFE924' />
        <path d='M9.64844 22.9921L17.1093 7.00732H20.3494L12.8886 22.9921H9.64844Z' fill='#333333' />
      </svg>
    </div>
  )
}

export { Logo, LogoIcon }
