'use client'

import * as React from 'react'

import { cn } from '@/lib/utils'

import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '@/components/ui/navigation-menu'
import { siteRoutes, tinyApiDocs } from '@/config/site'
import Link from 'next/link'

export function Resources() {
  return (
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationMenuItem>
          <NavigationMenuTrigger className='hidden gap-2 md:flex '>Resources</NavigationMenuTrigger>
          <NavigationMenuContent className='left-0'>
            <ul className='grid grid-cols-2 gap-2 p-4 md:w-[400px]'>
              <li>
                <a target='_blank' href={tinyApiDocs} rel='noreferrer'>
                  <ListItem title='API Docs'>Read Tiny API Docs</ListItem>
                </a>
              </li>
              <Link href={siteRoutes.roadmap}>
                <ListItem title='Roadmap'>Learn What’s coming next on Tiny API</ListItem>
              </Link>
              <Link href={siteRoutes.hub}>
                <ListItem title='How it works'>
                  Learn how Tiny API can help you earn a living while working in open source
                </ListItem>
              </Link>
              <Link href={'/resources/request-for-apis'}>
                <ListItem title='Requests for APIs'></ListItem>
              </Link>
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  )
}

const ListItem = React.forwardRef<React.ElementRef<'a'>, React.ComponentPropsWithoutRef<'a'>>(
  ({ className, title, children, ...props }, ref) => {
    return (
      <li>
        <NavigationMenuLink asChild>
          <a
            ref={ref}
            className={cn(
              'flex select-none flex-col gap-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground',
              className
            )}
            {...props}
          >
            <div className='text-sm font-medium leading-none'>{title}</div>
            <p className='line-clamp-2 text-sm leading-snug text-muted-foreground'>{children}</p>
          </a>
        </NavigationMenuLink>
      </li>
    )
  }
)
ListItem.displayName = 'ListItem'
