import { cn } from '@/lib/utils'
import { ReactNode } from 'react'

interface IconProps {
  link?: string
  name?: string
  onClick?: CallableFunction
  className?: string
  children?: ReactNode
}

function Icon(props: IconProps) {
  return (
    <div
      className={cn(
        'flex h-10 w-10 cursor-pointer items-center justify-center rounded-[10px] border border-opacity-[0.4] p-2',
        props.className
      )}
      onClick={() => props.onClick && props.onClick()}
    >
      {props.link ? <a href={props.link}>{props.children}</a> : props.children}
    </div>
  )
}

export default Icon
