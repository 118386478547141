import { useGetProjectListQuery, useGetUserQuery } from '@/lib/redux'
import { IProject } from '@/types/project'
import { useEffect, useState } from 'react'

export function useProject() {
  const { data: user, isLoading: isUserLoading } = useGetUserQuery()
  const { data: projects, isLoading: isProjectsLoading } = useGetProjectListQuery()
  const [activeProject, setActiveProject] = useState<IProject | null>(null)

  useEffect(() => {
    if (!isUserLoading && !isProjectsLoading && user?.results[0] && projects?.results) {
      setActiveProject(() => {
        const foundProject = projects?.results.find((project) => project.id === user?.results[0].lastActiveOrganisation)
        return foundProject ? foundProject : null
      })
    }
  }, [user, projects])

  return { activeProject: activeProject, isLoading: isUserLoading || isProjectsLoading }
}
