import { siteConfig } from '@/config/site'
import Icon from '../ui/icon'
import { BsGithub, BsLinkedin, BsTwitter } from 'react-icons/bs'

export function FooterIcons() {
  return (
    <div className='text-grey ml-10 flex space-x-4'>
      <Icon link={siteConfig?.links?.linkedin}>
        <BsLinkedin className='h-5 w-5' />
      </Icon>
      <Icon link={siteConfig?.links.github}>
        <BsGithub className='h-5 w-5' />
      </Icon>
      <Icon link={siteConfig?.links?.twitter}>
        <BsTwitter className='h-5 w-5' />
      </Icon>
    </div>
  )
}
