'use client'

import { cn } from '@/lib/utils'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import React, { HTMLAttributes } from 'react'

export type INavItem = {
  label: string
  href?: string
  Icon: React.ElementType
}

interface NavItemProps extends INavItem, HTMLAttributes<HTMLLinkElement> {}

export function NavItem(props: NavItemProps) {
  let isActive = false
  const pathname = usePathname()
  isActive = pathname.startsWith(props.href ?? '#')

  return (
    <Link
      className={cn(
        'flex items-center gap-1 px-3 py-2 font-ibm text-sm font-semibold',
        isActive ? 'text-primary' : 'text-[#DDDDDD]',
        props.className
      )}
      href={props.href || '#'}
    >
      <props.Icon className={cn('h-4 w-4 stroke-2', isActive ? 'stroke-primary stroke-[2px]' : 'stroke-[#DDDDDD]')} />
      {props.label}
    </Link>
  )
}

export function NavItemExternal(props: NavItemProps) {
  return (
    <a
      className={cn('flex items-center gap-1 px-3 py-2 font-ibm text-sm font-semibold text-white', props.className)}
      href={props.href || '#'}
      target='_blank'
      rel='noreferrer'
    >
      <props.Icon className={cn('h-4 w-4 stroke-2')} />
      {props.label}
    </a>
  )
}
